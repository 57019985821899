// const baseURL = 'https://demolinc.com/chotekisan/cp/api/';
// const baseURL = 'http://demo.chotekisan.com/admin/api/';
const baseURL = 'https://uat.chotekisan.com/admin/api/';
// const baseURL = 'https://chotekisan.com/admin/api/';
// const baseURL = 'http://13.201.108.95/admin/api/';
// const baseURL = 'http://192.168.1.244/ChotekisanApi/admin/index.php/api/';
// const baseURL = 'http://192.168.1.81/chotekisan/admin/api/';
// const baseURL = 'https://demolinc.com/chotekisan/admin/api/'; //statically add for new payment

const order_by = 'web'

export async function postRegister(fullName,mobilenumber) {
    try {
        const formData = new FormData();
        formData.append('name', fullName);
        formData.append('mobile', mobilenumber);
        const response = await fetch(baseURL + 'register', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function postVerifyOtp(mobile, otp, session_id) {
    try {
        const formData = new FormData();
        formData.append('mobile', mobile);
        formData.append('otp', otp);
        formData.append('session_id', session_id);
        const response = await fetch(baseURL + 'verifyotp', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}


export async function postResendOtp(mobile) {
    try {
        const formData = new FormData();
        formData.append('mobile', mobile);
        const response = await fetch(baseURL + 'resendotp', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getBannerList() {
    try {
        const response = await fetch(baseURL + `bannerlist?banner_for=${'2'}`, {
            method: 'POST',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getCategoryList() {
    try {
        const response = await fetch(baseURL + 'categoryslist', {
            method: 'POST',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getTodaySpecial(userId, guest, page, sort_by) {
    try {

        const response = await fetch(baseURL + `todayspecial?user_id=${userId}&user_type=${guest}&page=${page}&sort_by=${sort_by}`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getProductList(catId, userId, guest, page, sort_by) {
    try {

        const response = await fetch(baseURL + `productlist?cat_id=${catId}&user_id=${userId}&user_type=${guest}&page=${page}&sort_by=${sort_by}`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getProductDetails(productId, userId, guest) {
    try {

        const response = await fetch(baseURL + `getproductsdetails?product_id=${productId}&user_id=${userId}&user_type=${guest}`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getCartInsert(guest, userId, productId) {
    try {
        const formData = new FormData();
        formData.append('user_type', guest);
        formData.append('user_id', userId);
        formData.append('product_id', productId);
        formData.append('order_by', order_by);
        const response = await fetch(baseURL + 'cartinsert', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}


export async function postCartUpdate(userId, productId, updatedQuantity, guest) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('product_id', productId);
        formData.append('quantity', updatedQuantity);
        formData.append('user_type', guest);
        formData.append('order_by', order_by);
        const response = await fetch(baseURL + 'cartupdate', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getCartList(userId, guest) {
    try {

        const response = await fetch(baseURL + `cartlisting?user_id=${userId}&user_type=${guest}&order_by=${order_by}`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}


export async function postCartDelete(cartId, userId, guest) {
    try {
        const formData = new FormData();
        formData.append('cart_id', cartId);
        formData.append('user_id', userId);
        formData.append('user_type', guest);
        const response = await fetch(baseURL + 'cartdelete', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getCartCount(userId, guest) {
    try {

        const response = await fetch(baseURL + `cartcount?user_id=${userId}&user_type=${guest}`, {
            method: 'GET',
        });
        const data = await response.json();
        return data.data.quantity_list[0]?.cartcount || 0;
    }
    catch (error) {
        return [];
    }
}

export async function postCartEmpty(userId, guest) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('user_type', guest);
        const response = await fetch(baseURL + 'cartempty', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getSearchproduct(searchKey, userId, user_type, searchList, catId, page) {
    try {
        const response = await fetch(baseURL + `searchproduct?key=${searchKey}&user_id=${userId}&user_type=${user_type}&search_list=${searchList}&cat_id=${catId}&page=${page}`, {
            method: 'POST',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function postProductFilter(sortOption) {
    try {
        const formData = new FormData();
        formData.append('product_name', sortOption);
        const response = await fetch(baseURL + 'productfilter', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function postAddress(userId, cust_name, cust_mobile, cust_email, cust_address, cust_area, cust_landmark, cust_state, cust_city, cust_pincode) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('customer_name', cust_name);
        formData.append('customer_mobile', cust_mobile);
        formData.append('customer_email', cust_email);
        formData.append('customer_address', cust_address);
        formData.append('customer_area', cust_area);
        formData.append('customer_landmark', cust_landmark);
        formData.append('customer_state', cust_state);
        formData.append('customer_city', cust_city);
        formData.append('customer_pincode', cust_pincode);

        const response = await fetch(baseURL + 'address', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function postAddressList(userId) {
    try {
        const response = await fetch(baseURL + `addresslisting?user_id=${userId}`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function postUpdateAddress(userId, cust_name, cust_mobile, cust_email, cust_address, cust_area, cust_landmark, cust_state, cust_city, cust_pincode, addressId) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('customer_name', cust_name);
        formData.append('customer_mobile', cust_mobile);
        formData.append('customer_email', cust_email);
        formData.append('customer_address', cust_address);
        formData.append('customer_area', cust_area);
        formData.append('customer_landmark', cust_landmark);
        formData.append('customer_state', cust_state);
        formData.append('customer_city', cust_city);
        formData.append('customer_pincode', cust_pincode);
        formData.append('address_id', addressId);

        const response = await fetch(baseURL + 'addressupdate', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function postAddressDelete(userId, addressId) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('address_id', addressId);
        const response = await fetch(baseURL + 'addressdelete', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function postFavourites(userId, productId) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('product_id', productId);
        const response = await fetch(baseURL + 'favourites', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function postFavouriteList(userId, guest) {
    try {
        const response = await fetch(baseURL + `favouriteslist?user_id=${userId}&user_type=${guest}`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function postFavouriteRemove(userId, productId) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('product_id', productId);
        const response = await fetch(baseURL + 'favouritesremove', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function postDefaultAddress(addressId, userId) {
    try {
        const formData = new FormData();
        formData.append('address_id', addressId);
        formData.append('user_id', userId);
        const response = await fetch(baseURL + 'defaultaddress', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function PostCheckoutProcess(userId) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        const response = await fetch(baseURL + `checkoutprocess`, {
            method: 'POST',
            body: formData,

        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}


export async function getProfile(userId) {
    try {
        const response = await fetch(baseURL + `profile?user_id=${userId}`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function postProfileEdit(userId, name, mobile, email, gender, d_o_b) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('name', name);
        formData.append('mobile', mobile);
        formData.append('email', email);
        formData.append('gender', gender);
        formData.append('d_o_b', d_o_b);

        const response = await fetch(baseURL + 'profileEdit', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getOrderList(userId) {
    try {
        const response = await fetch(baseURL + `orderlisting?user_id=${userId}&order_by=${order_by}`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}
// export async function getOrderList(userId) {
//     try {
//         const formData = new FormData();
//         formData.append('user_id', userId);
//         const response = await fetch(baseURL + 'orderlisting', {
//             method: 'POST',
//             body: formData,
//         });
//         return response.json();
//     }
//     catch (error) {
//         return [];
//     }
// }

export async function getOrderDetails(userId,orderId) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('order_id', orderId);
        const response = await fetch(baseURL + `orderdetails`, {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function CMSRequest(data) {
    try {
        const response = await fetch(baseURL + `cms?page_url=${data}`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function ContactUsDetailsSend(fullName, phoneNumber, email, messages) {
    try {
        const formData = new FormData();
        formData.append('name', fullName);
        formData.append('mobile', phoneNumber);
        formData.append('email', email);
        formData.append('message', messages);
        formData.append('feedback_from', '1');

        const response = await fetch(baseURL + 'enquirySave', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function generalData() {
    try {

        const response = await fetch(baseURL + `generalData`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getOrderConfrim(userId, shippingId, payment,paymentId) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('shipping_address_id', shippingId);
        formData.append('order_by', order_by);
        formData.append('payment_type', payment);
        formData.append('payment_id', paymentId);
        const response = await fetch(baseURL + 'OrderConfirm', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function relatedProducts(data, userId, usertype) {
    try {
        const response = await fetch(baseURL + `relatedProducts?product_slug=${data}&user_id=${userId}&user_type=${usertype}`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}
export async function getFaq() {
    try {
        const response = await fetch(baseURL + `faq`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getTestimonial() {
    try {
        const response = await fetch(baseURL + `testimonialData`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getCityList() {
    try {
        const response = await fetch(baseURL + `city`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getPinCodeList(city_id) {
    try {
        const formData = new FormData();
        formData.append('city_name', city_id);
        const response = await fetch(baseURL + 'pincode', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function postLocationByPinCode(pincode) {
    try {
        const formData = new FormData();
        formData.append('pincode', pincode);
        const response = await fetch(baseURL + 'pincodeAvailability', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function productAvailability(userId,usertype) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('user_type', usertype);
        const response = await fetch(baseURL + `productAvailability`, {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function postSharerData(productId) {
    try {
        const formData = new FormData();
        formData.append('product_id', productId);
        const response = await fetch(baseURL + `socialShare/${productId}`, {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getOrderSettings() {
    try {
        const response = await fetch(baseURL + `orderSettings`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function userValidate(userId) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        const response = await fetch(baseURL + `userValidate`, {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getCartPrice(userId) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        // formData.append('product_id', '');
        const response = await fetch(baseURL + `cartPriceUpdate`, {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getTrackOrder(userId, orderId) {
    try {

        const response = await fetch(baseURL + `trackorder?user_id=${userId}&order_id=${orderId}`, {
            method: 'GET',
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getwebOrderConfirm(userId, shippingId, payment) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('shipping_address_id', shippingId);
        formData.append('order_by', order_by);
        formData.append('payment_type', payment);
        // formData.append('delivery_charge', 0);

        // formData.append('payment_id', '1');
        const response = await fetch(baseURL + 'webOrderConfirm', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function getuserOrderId(userId) {
    try {
        const formData = new FormData();
        formData.append('user_id', userId);
        // formData.append('payment_id', '1');
        const response = await fetch(baseURL + 'getUserOrderId', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}

export async function promotionOffer(mobilenumber) {
    try {
        const formData = new FormData();
        formData.append('reg_mobile_no', mobilenumber);
        const response = await fetch(baseURL + 'promotionOffer', {
            method: 'POST',
            body: formData,
        });
        return response.json();
    }
    catch (error) {
        return [];
    }
}
